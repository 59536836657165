<script setup lang="ts">
const theme = useTheme()

const user = useSupabaseUser()

function toggleTheme() {
  theme.global.name.value = theme.global.current.value.dark ? 'light' : 'dark'
}

const drawer = ref(true)
const rail = ref(true)

const links = [
  { icon: 'material-symbols:archive', title: 'Import', to: '/import/tiktok' },
  { icon: 'material-symbols:video-library', title: 'Videos', to: '/videos' },
  { icon: 'material-symbols:schedule', title: 'Schedules', to: '/schedules' },
]

watch(theme.global.name, themeTransition)

function themeTransition() {
  const x = performance.now()
  for (let i = 0; i++ < 1e7; i << 9 & 9 % 9 * 9 + 9);
  if (performance.now() - x > 10) return
  const el = document.querySelector('#__nuxt')

  const children = el.querySelectorAll('*')

  children.forEach((el) => {
    if (hasScrollbar(el)) {
      el.dataset.scrollX = String(el.scrollLeft)
      el.dataset.scrollY = String(el.scrollTop)
    }
  })

  const copy = el.cloneNode(true)
  copy.classList.add('app-copy')
  const rect = el.getBoundingClientRect()
  copy.style.top = `${rect.top}px`
  copy.style.left = `${rect.left}px`
  copy.style.width = `${rect.width}px`
  copy.style.height = `${rect.height}px`

  const targetEl = document.activeElement
  const targetRect = targetEl.getBoundingClientRect()
  const left = targetRect.left + targetRect.width / 2 + window.scrollX
  const top = targetRect.top + targetRect.height / 2 + window.scrollY
  el.style.setProperty('--clip-pos', `${left}px ${top}px`)
  el.style.removeProperty('--clip-size')

  nextTick(() => {
    el.classList.add('app-transition')
    requestAnimationFrame(() => {
      requestAnimationFrame(() => {
        el.style.setProperty('--clip-size', `${Math.hypot(window.innerWidth, window.innerHeight)}px`)
      })
    })
  })

  document.body.append(copy)

  copy.querySelectorAll('[data-scroll-x], [data-scroll-y]').forEach((el) => {
    el.scrollLeft = +el.dataset.scrollX
    el.scrollTop = +el.dataset.scrollY
  })

  function onTransitionend(e) {
    if (e.target === e.currentTarget) {
      copy.remove()
      el.removeEventListener('transitionend', onTransitionend)
      el.removeEventListener('transitioncancel', onTransitionend)
      el.classList.remove('app-transition')
      el.style.removeProperty('--clip-size')
      el.style.removeProperty('--clip-pos')
    }
  }
  el.addEventListener('transitionend', onTransitionend)
  el.addEventListener('transitioncancel', onTransitionend)
}

function hasScrollbar(el) {
  if (!el || el.nodeType !== Node.ELEMENT_NODE) return false

  const style = window.getComputedStyle(el)
  return style.overflowY === 'scroll' || (style.overflowY === 'auto' && el.scrollHeight > el.clientHeight)
}
</script>

<template>
  <v-responsive class="border rounded">
    <v-app>
      <v-app-bar>
        <!-- <v-app-bar-nav-icon variant="text" @click.stop="rail = !rail" /> -->

        <v-toolbar-title>cloutware</v-toolbar-title>

        <v-spacer />

        <v-btn
          :prepend-icon="theme.global.name.value === 'light' ? 'mdi-weather-sunny' : 'mdi-weather-night'"
          text="Toggle Theme"
          slim
          @click="toggleTheme"
        />
      </v-app-bar>

      <v-navigation-drawer
        v-model="drawer"
        permanent
        expand-on-hover
        :rail
      >
        <v-list-item
          to="/account"

          :prepend-avatar="user?.user_metadata.picture"
          :title="user?.user_metadata.name || user?.email"
          lines="one"
        />
        <v-list density="compact" nav>
          <v-divider />
          <v-list-item v-for="{ icon: prependIcon, title, to } in links" :key="to" :prepend-icon :title :to />
        </v-list>
        <template #append />
      </v-navigation-drawer>

      <v-main>
        <v-container>
          <slot />
        </v-container>
      </v-main>

      <v-bottom-navigation mode="shift" grow>
        <v-btn v-for="{ icon: prependIcon, title, to } in links" :key="to" :prepend-icon :text="title" :to />
      </v-bottom-navigation>
    </v-app>
  </v-responsive>
</template>

<style lang="sass">
::view-transition-old(root), ::view-transition-new(root), ::view-transition-old(*), ::view-transition-new(*)
  animation-duration: 200ms
@media (prefers-reduced-motion)
  ::view-transition-old, ::view-transition-new
    animation: none
.app-copy
  position: fixed !important
  z-index: -1 !important
  pointer-events: none !important
  contain: size style !important
  overflow: clip !important

.app-transition
  --clip-size: 0
  --clip-pos: 0 0
  clip-path: circle(var(--clip-size) at var(--clip-pos))
  transition: clip-path .35s ease-out
</style>
